import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['imageRevealPassive', 'imageRevealAssertive', 'imageRevealAggressive'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const ImageReveal = makeShortcode("ImageReveal");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "assertive-communication"
    }}>{`Assertive communication`}</h1>
    <p>{`What might your teenager think or feel when you use these different communication styles?`}</p>
    <p>{`Click on `}<em parentName="p">{`Passive`}</em>{`, `}<em parentName="p">{`Assertive`}</em>{` and `}<em parentName="p">{`Aggressive`}</em>{` to reveal the answer.`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm mdxType="Grid">
    <ImageReveal id="imageRevealPassive" fadeSpeed={2000} mainItem={{
          src: '/images/m5/27-01.svg',
          alt: 'passive head'
        }} aboveReveal={{
          src: '/images/m5/27-05.svg',
          alt: 'passive bubble'
        }} mdxType="ImageReveal" />
  </Grid>
  <Grid item xs={12} sm mdxType="Grid">
    <ImageReveal id="imageRevealAssertive" fadeSpeed={2000} mainItem={{
          src: '/images/m5/27-02.svg',
          alt: 'assertive head'
        }} aboveReveal={{
          src: '/images/m5/27-04.svg',
          alt: 'assertive bubble'
        }} mdxType="ImageReveal" />
  </Grid>
  <Grid item xs={12} sm mdxType="Grid">
    <ImageReveal id="imageRevealAggressive" fadeSpeed={2000} mainItem={{
          src: '/images/m5/27-03.svg',
          alt: 'aggressive head'
        }} aboveReveal={{
          src: '/images/m5/27-06.svg',
          alt: 'aggressive bubble'
        }} mdxType="ImageReveal" />
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      